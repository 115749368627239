<template>
  <NbCardBox class="contacts">
    <h3 class="heading-3">
      {{ $t("accessibilitySettingsPage.stillHavingDifficulties") }}
    </h3>
    <p class="body-3">
      {{ $t("accessibilitySettingsPage.weWantToHelpYou") }}
    </p>

    <div class="items">
      <NbButton>{{ $t("accessibilitySettingsPage.contactViaPhone") }}</NbButton>
      <NbButton>{{ $t("accessibilitySettingsPage.contactViaEmail") }}</NbButton>
      <NbButton>{{ $t("accessibilitySettingsPage.contactViaChat") }}</NbButton>
    </div>
  </NbCardBox>
</template>

<script>
import NbCardBox from "../../cards/NbCardBox.vue";
import NbButton from "../../buttons/NbButton.vue";

export default {
  name: "NbContactsCard",
  components: { NbCardBox, NbButton },
};
</script>

<style lang="scss" scoped>
.contacts {
  @media screen and (min-width: 750px) {
    max-width: 21.56rem;
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
