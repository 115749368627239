<template>
  <div class="accessibility">
    <NbPageTitle
      :title="$t('components.nav.Accessibility')"
      :subTitle="$t('accessibilitySettingsPage.subtitle')"
    />

    <div class="content">
      <NbContactsCard v-if="false" />
      <div class="boxes">
        <NbTypographies class="box" />

        <NbCardBox class="box">
          <h3 class="heading-3">
            {{ $t("accessibilitySettingsPage.language") }}
          </h3>
          <p>{{ $t("accessibilitySettingsPage.mainLanguageDescription") }}</p>

          <div class="sizes-container">
            <h2 class="heading-2">
              {{ $t("accessibilitySettingsPage.selectLanguage") }}
            </h2>

            <Locale />
          </div>
        </NbCardBox>

        <div class="box help">
          <p class="body-2 m-0 text-center">
            {{ $t("printingsPage.ifYouPrefer") }}
            <router-link to="/settings/advanced" class="link-2"
              >{{
                $t("accessibilitySettingsPage.changeUnitsText")
              }}
              ></router-link
            >
          </p>
        </div>
      </div>
    </div>

    <NbPageFooter>
      <NbPageFooterText>{{
        $t("components.footer.needHelp")
      }}</NbPageFooterText>
      <NbPageFooterLink href="#">{{
        $t("components.footer.learnMoreAboutAccessibilityOptions")
      }}</NbPageFooterLink>
    </NbPageFooter>
  </div>
</template>

<script>
import NbCardBox from "../../components/cards/NbCardBox.vue";
import NbPageTitle from "../../components/pagescomponents/NbPageTitle.vue";
import Locale from "../../components/accessibility/Locale.vue";
import NbPageFooter from "../../components/footers/NbPageFooter.vue";
import NbPageFooterText from "../../components/footers/NbPageFooterText.vue";
import NbPageFooterLink from "../../components/footers/NbPageFooterLink.vue";
import NbContactsCard from "../../components/settings/accessibility/NbContactsCard.vue";
import NbTypographies from "../../components/settings/accessibility/NbTypographies.vue";

export default {
  name: "Accessibility",
  components: {
    NbPageTitle,
    NbCardBox,
    Locale,
    NbPageFooter,
    NbPageFooterText,
    NbPageFooterLink,
    NbContactsCard,
    NbTypographies,
  },
};
</script>

<style lang="scss" scoped>
.accessibility {
  display: flex;
  min-height: calc(100vh - (81px));
  flex-direction: column;
  .content {
    flex: 1 1 0%;
    margin-bottom: 54px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: 750px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .boxes {
        order: -1;
      }
    }
  }
  .boxes {
    .box {
      & + .box {
        margin-top: 16px;
      }
      &.help {
        background-color: var(--gray-05);
        padding: 1rem;
        border-radius: 8px;
      }
    }
    max-width: 32.75rem;
    width: 100%;
  }
}
</style>
